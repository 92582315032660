import { TLang } from 'lang/_langType'
import { setLanguage } from 'lib/functions'
import projectInfo from '../../../package.json'
import './Footer.css'

interface Props {
  language: TLang
}

/**
 * The footer to be displayed on every route.
 * @author Casper Tollefsen <casper.tollefsen@mobit.no>
 * @version 0.0.1
 * @param language The language to use
 * @returns A footer component
 * @since 0.0.1
 */
function Footer({
  language
}: Props) {

  const lang = setLanguage(language)
  const version = projectInfo.version

  return (
    <footer className="Footer">
      <p>{ lang.WORK_IN_PROGRESS } | v{ version }</p>
      <p>{ lang.DEVELOPED_BY } <strong>Mobit Stakkevollvegen</strong></p>
    </footer>
  )
}

export default Footer