import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import './App.css'
import IndexRoute from 'routes/IndexRoute/IndexRoute'
import TableRoute from 'routes/TableRoute/TableRoute'
import CountdownRoute from 'routes/CountdownRoute/CountdownRoute'
import Footer from 'components/Footer/Footer'

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <IndexRoute language="no" />
          </Route>
          <Route path="/countdown/:stopPlace">
            <CountdownRoute language="no" />
          </Route>
          <Route path="/:stopPlace">
            <TableRoute language="no" />
          </Route>
        </Switch>
        <Footer language="no" />
      </div>
    </Router>
  )
}

export default App;
