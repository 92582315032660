import { to24hrClock } from 'lib/functions'

interface Props {
  departureTime: Date
  destination: string
  key: number
  line: string
  transport: JSX.Element | string
}

function TableDataRow(props: Props) {
  return (
    <tr key={ props.key }>
      <td className="shrink">
        { props.line }
      </td>
      <td className="center shrink">
        { props.transport }
      </td>
      <td>
        { props.destination }
      </td>
      <td className="center">
        { to24hrClock(props.departureTime) }
      </td>
    </tr>
  )
}

export default TableDataRow