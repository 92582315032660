import React from "react"

interface BoatProps {
  width?: number
}

function Boat({
  width = 400
}: BoatProps) {
  return (
    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      style={{
        width: `${ width }px`
      }}
    >
      <path fill="none" d="M0 0h32v32H0z" />
      <path
        d="M15.989 26.496c-.217.001-3.708.003-4.408-1.424-.528 1.076-3.233 1.34-4.652 1.403a.774.774 0 01-.848-.77c-.024-.217-.024-.451-.024-.666 0-.413.317-.758.729-.794 1.373-.047 4.248-.319 4.795-1.432.723 1.473 4.419 1.473 4.419 1.473s3.696 0 4.419-1.473c.547 1.113 3.422 1.385 4.794 1.451.402.035.71.372.71.775.02.215.02.449.02.666a.8.8 0 01-.874.795c-1.417-.088-4.122-.352-4.65-1.428C19.696 26.545 16 26.495 16 26.495l-.011.001zm4.06-20.992c.243 0 .484.057.701.166l1.164.582c.449.224.764.65.846 1.145l.974 5.841s.698.366 1.309.508a.774.774 0 01.536 1.058L23.5 21.246c-1.135-.14-2.646-.493-3.081-1.379-.717 1.461-2.911 2.198-4.384 2.209H16c-1.473 0-3.696-.736-4.419-2.209-.435.886-1.946 1.239-3.081 1.379l-2.079-6.442a.772.772 0 01.536-1.058c.611-.142 1.309-.508 1.309-.508l.974-5.841c.082-.495.397-.921.846-1.145l1.164-.582c.217-.109.458-.166.701-.166h8.098zm-.268 2.21h-7.562a.775.775 0 00-.76.626c-.286 1.481-.621 4.301-.621 4.301l4.954-1.149a.91.91 0 01.208-.024h.013a.913.913 0 01.195.024l4.954 1.149s-.335-2.82-.621-4.301a.775.775 0 00-.76-.626z"
      />
    </svg>
  )
}

export default Boat
