import React from 'react'
import { TLang } from 'lang/_langType'
import { setLanguage } from 'lib/functions'
import './IndexRoute.css'
import { Link } from 'react-router-dom'

export interface IndexRouteProps {
  /**
   * What language to use?
   */
  language: TLang
}

/**
 * The index route.
 * @author Casper Tollefsen <casper.tollefsen@mobit.no>
 * @version 1.0.0
 * @path '/'
 * @param language The language to use
 * @since 0.0.1
 * @public
 */
function IndexRoute({
  language
}: IndexRouteProps) {
  
  // Set language
  const lang = setLanguage(language)

  return (
    <div className="IndexRoute">
      <div className="container">
        <h1>{ lang.GREETING }</h1>
        <p>Klikk på en link for å vise rutetabellen</p>
        <Link to="/52281">Tromsø Lufthavn</Link><br />
        <Link to="/52806">Scandic Ishavshotel</Link><br />
        <Link to="/55958">Grand Nordic Hotel</Link>
      </div>
    </div>
  )
}

export default IndexRoute
