import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getApiData, setLanguage, useQuery } from 'lib/functions'
import { IApiCountdownData } from 'typings/interfaces'
import EtdCounter from 'components/EtdCounter/EtdCounter'
import { TLang } from 'lang/_langType'
import './CountdownRoute.css'

interface CountdownRouteProps {
  language: TLang
}

/**
 * Displays the time until next departure
 * @author Casper Tollefsen <casper.tollefsen@mobit.no>
 * @version 1.0.0
 * @path '/countdown/:stopPlace'
 * @param language The language to use
 * @since 0.0.1
 * @public
 */
function CountdownRoute({
  language
}: CountdownRouteProps) {
  const [apiCountdownData, setApiCountdownData] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadingError, setLoadingError] = useState(null)

  // Get NSR:StopPlace from URI-parameter
  const { stopPlace } = useParams<{ stopPlace: string }>()
  // Set background color
  const bg = useQuery().get('bg')
  // Set foreground color
  const fg = useQuery().get('fg')

  // Set language
  const lang = setLanguage(language)

  // GraphQL fetch query
  const query = `{
    stopPlace(id: "NSR:StopPlace:${ stopPlace }") {
      estimatedCalls(numberOfDepartures: 1) {
        expectedDepartureTime
      }
    }
  }`

  useEffect(() => {
    setIsLoading(true)
    getApiData(query)
      .then((res: IApiCountdownData) => {
        setApiCountdownData(res.data.stopPlace.estimatedCalls[0].expectedDepartureTime)
      })
      .catch((err) => {
        setLoadingError(err)
      })
    setIsLoading(false)
  }, [query])

  // Change CSS variables based on URL-query
  bg && document.documentElement.style.setProperty('--primary', bg)
  fg && document.documentElement.style.setProperty('--secondary', fg)

  return (
    <div
      className="CountdownRoute"
    >
      {(function() {
        if (isLoading) {
          return <p>{ lang.LOADING }</p>
        }
        else if (loadingError) {
          return <p>{ lang.LOADING_ERROR }</p>
        }
        else if (apiCountdownData.length > 0) {
          return <EtdCounter etd={ new Date(apiCountdownData) } language={ language } />
        }
        else {
          return <p>{ lang.LOADING }</p>
        }
      })()}
    </div>
  )
}

export default CountdownRoute
