import { ILang } from './_langType'

const no: ILang = {
  AWAITING_STARTUP: 'Starter opp systemet...',
  BUS: 'Buss',
  DEPARTURE: 'Avgang',
  DEPARTURE_TIME: 'Avgangstid',
  DESTINATION: 'Destinasjon',
  DEVELOPED_BY: 'Utviklet av',
  GREETING: 'Velkommen!',
  LINE: 'Rute',
  LOADING: 'Laster...',
  LOADING_ERROR: 'Noe gikk galt med henting av data fra Entur!',
  METRO: 'T-bane',
  NEXT_DEPARTURE: 'Neste avgang',
  STOP_PLACE: 'Holdeplass',
  TRAIN: 'Tog',
  TRAM: 'Trikk',
  WORK_IN_PROGRESS: 'Under utvikling'
}

export default no