import React from "react"

interface BusProps {
  width?: number
}

function Bus({
  width = 400
}: BusProps) {
  return (
    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      style={{
        width: `${ width }px`
      }}
    >
      <path fill="none" d="M0 0h32v32H0z" />
      <path
        d="M7 10.457h-.495a.507.507 0 00-.505.505v3.033a.502.502 0 00.505.505H7v7.529c0 .838.396 1.578 1 2.025V25.5a1 1 0 001 1h2a1 1 0 001-1v-1h8v1a1 1 0 001 1h2a1 1 0 001-1v-1.446c.604-.447 1-1.187 1-2.025V14.5h.495a.502.502 0 00.505-.505v-3.033a.504.504 0 00-.505-.505H25V7.971C25 6.607 23.951 5.5 22.659 5.5H9.341C8.049 5.5 7 6.607 7 7.971v2.486zM11 21.5a1 1 0 10-2 0 1 1 0 002 0zm12 0a1 1 0 10-2 0 1 1 0 002 0zm-13.22-2a.78.78 0 01-.78-.78v-8.44a.78.78 0 01.78-.78h12.44a.78.78 0 01.78.78v8.44a.78.78 0 01-.78.78H9.78zm12.253-11a.97.97 0 00.684-.283.97.97 0 00.283-.684v-.066a.97.97 0 00-.283-.684.97.97 0 00-.684-.283H9.967a.97.97 0 00-.684.283.97.97 0 00-.283.684v.066a.97.97 0 00.283.684.97.97 0 00.684.283h12.066z"
      />
    </svg>
  )
}

export default Bus
