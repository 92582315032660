import React from "react"

interface PlaneDepartureProps {
  width?: number
}

function PlaneDeparture({
  width = 400
}: PlaneDepartureProps) {
  return (
    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      style={{
        width: `${ width }px`
      }}
    >
      <path fill="none" d="M0 0h32v32H0z" />
      <path
        d="M17.828 11.125l4.84-4.84c.703-.703 2.269-1.127 2.972-.425.702.703.278 2.269-.425 2.972l-4.84 4.84.887 2.509.238-.238a.45.45 0 01.637 0l.637.637a.449.449 0 010 .636l-.847.847.665 1.882.182-.181a.45.45 0 01.637 0l.637.636a.452.452 0 010 .637l-.79.79 1.007 2.852a.478.478 0 01-.113.499l-.489.489a.478.478 0 01-.73-.061l-6.039-8.453-5.158 5.158v3.197c0 .127-.05.25-.14.34l-.512.511a.479.479 0 01-.786-.161l-1.427-3.569-3.569-1.427a.48.48 0 01-.161-.786l.511-.512c.09-.09.213-.14.34-.14h3.197l5.158-5.158-8.453-6.039a.48.48 0 01-.061-.73l.489-.489a.478.478 0 01.499-.113l2.852 1.007.79-.79a.452.452 0 01.637 0l.636.637a.45.45 0 010 .637l-.181.182 1.882.665.847-.847a.449.449 0 01.636 0l.637.637a.45.45 0 010 .637l-.238.238 2.509.887z"
      />
    </svg>
  )
}

export default PlaneDeparture
