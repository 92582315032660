import React from 'react'
import {
	//ILang,
	TLang
} from 'lang/_langType'
//import { setLanguage } from 'lib/functions'
import './LocationName.css'

interface LocationNameProps {
	language?: TLang
	locationName: string
}

/**
 * A component that displays the departure location.
 * @author Casper Tollefsen <casper.tollefsen@mobit.no>
 * @version 1.0.0
 * @param language The language to use
 * @param locationName The name of the stop place
 * @returns A heading component
 * @since 0.0.1
 */
function LocationName({
	language = 'no',
	locationName
}: LocationNameProps): JSX.Element {

	// Set language
	//const lang: ILang = setLanguage(language)

	return (
		<div className="LocationName">
			{/* <p>{ lang.STOP_PLACE }</p> */}
			<h1>{ locationName }</h1>
		</div>
	)
}

export default LocationName
