import React from "react"

interface TrainProps {
  width?: number
}

function Train({
  width = 400
}: TrainProps) {
  return (
    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      style={{
        width: `${ width }px`
      }}
    >
      <path fill="none" d="M0 0h32v32H0z" />
      <path
        d="M10.818 22.863l-2.717 2.716a.783.783 0 101.108 1.108l1.181-1.181h11.22l1.181 1.181a.783.783 0 101.108-1.108l-2.717-2.716h1.08c1.215 0 2.201-1.041 2.201-2.324V8.24c0-1.282-.986-2.323-2.201-2.323H9.738c-1.215 0-2.201 1.041-2.201 2.323v12.299c0 1.283.986 2.324 2.201 2.324h1.08zm8.287 0h-6.21a.779.779 0 01-.2.338l-.738.738h8.086l-.738-.738a.779.779 0 01-.2-.338zm-7.807-2.842a.94.94 0 10-1.88 0 .94.94 0 001.88 0zm11.284 0a.94.94 0 10-1.88 0 .94.94 0 001.88 0zM15.06 7.797v8.463h-4.909a.733.733 0 01-.733-.734V8.531c0-.405.328-.734.733-.734h4.909zm1.88 0h4.909c.405 0 .733.329.733.734v6.995a.733.733 0 01-.733.734H16.94V7.797z"
      />
    </svg>
  )
}

export default Train
