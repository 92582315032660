import React, { useEffect, useState } from 'react'
import Table from 'components/Table/Table'
import { TLang } from 'lang/_langType'
import { getApiData, getDepartureList, setLanguage, useQuery } from 'lib/functions'
import { useParams } from 'react-router'
import LocationName from 'components/LocationName/LocationName'
import { IApiData } from 'typings/interfaces'
import './TableRoute.css'

export interface TableRouteProps {
  language: TLang
}

/**
 * Displays the table of departures.
 * @author Casper Tollefsen <casper.tollefsen@mobit.no>
 * @version 1.0.0
 * @path '/:stopPlace'
 * @param language The language to use
 * @since 0.0.1
 * @public
 */
function TableRoute({
  language
}: TableRouteProps) {
  // Set states
  const [apiData, setApiData] = useState<IApiData>({
    data: {
      stopPlace: {
        name: "",
        id: "",
        estimatedCalls: []
      }
    }
  })
  const [isLoading, setIsLoading] = useState(false)
  const [loadingError, setLoadingError] = useState(null)

  // Get URL /:stopPlace
  const { stopPlace } = useParams<{ stopPlace: string }>()

  const urlQuery = useQuery()

  // Set background color
  const bg = urlQuery.get('bg')
  // Set foreground color
  const fg = urlQuery.get('fg')
  // Set number of departure rows to display
  const limit = urlQuery.get('limit') || 7
  // Enable/disable LocationName component
  const showLocation = urlQuery.get('showLocation') || 'true'

  // Set language
  const lang = setLanguage(language)

  // GraphQL fetch query
  const query = `{
    stopPlace(id: "NSR:StopPlace:${ stopPlace }") {
      name
      id
      estimatedCalls(numberOfDepartures: ${ limit }) {
        expectedDepartureTime
        destinationDisplay {
          frontText
        }
        serviceJourney {
          line {
            publicCode
            transportMode
          }
        }
      }
    }
  }`

  useEffect(() => {
    setIsLoading(true)
    getApiData(query)
      .then((res: IApiData) => {
        setApiData(res)
      })
      .catch((err) => {
        setLoadingError(err)
      })
    setIsLoading(false)
  }, [query])

  // Change CSS variables based on URL-query
  bg && document.documentElement.style.setProperty('--primary', bg)
  fg && document.documentElement.style.setProperty('--secondary', fg)

  return (
    <div className="TableRoute">
      {(function() {
        if (isLoading) {
          return <p>{ lang.LOADING }</p>
        }
        else if (loadingError) {
          return <p>{ lang.LOADING_ERROR }</p>
        }
        else {
          return (
            <>
            {showLocation !== 'false' && (
              <LocationName language={ language } locationName={ apiData.data.stopPlace.name } />
            )}
            <Table departureList={ getDepartureList(apiData) } language={ language } />
            </>
          )
        }
      })()}
    </div>
  )
}

export default TableRoute
