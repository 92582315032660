import { ILang } from './_langType'

const en: ILang = {
  AWAITING_STARTUP: 'Starting system...',
  BUS: 'Bus',
  DEPARTURE: 'Departure',
  DEPARTURE_TIME: 'Departure time',
  DESTINATION: 'Destination',
  DEVELOPED_BY: 'Developed by',
  GREETING: 'Welcome!',
  LINE: 'Line',
  LOADING: 'Loading...',
  LOADING_ERROR: 'Something went wrong when trying to fetch data from Entur!',
  METRO: 'Metro',
  NEXT_DEPARTURE: 'Next departure',
  STOP_PLACE: 'Stop',
  TRAIN: 'Train',
  TRAM: 'Tram',
  WORK_IN_PROGRESS: 'Work in progress'
}

export default en